import React from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import { Link } from "gatsby"

const HelpCenter = props => {
  const { termsAndConditionsContent, module } = props.pageContext

  return (
    <Layout seoTitle={module.seoTitle}>
      <Container isCentered>
        <h2>Terms and Conditions</h2>
        <hr />
        {termsAndConditionsContent.map(section => {
          return (
            <div className="content is-medium">
              <h3>{section.section}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: section.body.childMarkdownRemark.html,
                }}
              ></div>
            </div>
          )
        })}
      </Container>
    </Layout>
  )
}

export default HelpCenter
